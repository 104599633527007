.container {
   display: flex;
   flex-direction: row;
   align-content: center;
   justify-content: space-between;
   border: 1px solid #f1f1f1;
   margin: 10px;

   min-height: 80vh;
   flex: 1;
}
.instructionContainer{
  display: flex;
  width: 'fit-content';
  flex-direction:column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  border-bottom: 1px solid #f2f2f2;
  margin: 0;
}

.saveFormWrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-self: center; */
  gap: 20px;
  align-items: center;
  align-content: center;
  margin-left: 10px;
flex: 1;
width: '1000px';
 
}

.saveContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  width: "100%";
  margin-bottom: 20px;
  

}
.tableWrapper {
  flex: 0.8;
  width: "fit-content";
  max-width: '70%';
  overflow-x: scroll;
  position: relative;
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  border: 1px solid rgb(207, 207, 207);
 
}
.titleContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
  
}
.modalFooter {
   
   
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    /* background-color: aquamarine; */
    gap: 10px;
}

.diemensionsWrapper {
  flex:0.2;
  background-color: #f1f1f1;
  border-radius: 4px;
  padding: 10;
}