::-webkit-scrollbar {
    width: 5px; /* Width of the scrollbar */
  }
  
  ::-webkit-scrollbar-thumb {
    border-bottom: 0.5px solid #b9b9b90a;
    border-radius: 4px; /* Border radius of the thumb */
  }
  
  /* For Firefox-based browsers (Firefox) */
  /* Adjust the color, width, and other properties as desired */


.navHeader {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    border-bottom: 0.5px solid #b9b9b90a;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    position: sticky;
    top: 0;
    background-color: var(--primaryColor);
    z-index: 100;

}
.footerText {
    font-size: small;
    text-align: center;
}
.navigation{
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
}

.logo {
    width: 45px;
    height: 45px;
}

ul {
    list-style: none;
    padding: 0px;
    margin: 0;
}

ul>li {
    padding: 0;
    margin: 0;
    padding: 0px;
    cursor: pointer;
    background-color: #d8d8d80a;
}
nav{
    width: 100%;
    position: sticky;
    overflow-y: scroll;
}
.li-icon {
    margin-right: 10px;
    vertical-align: middle;
    align-self: center;
}

nav {
    margin-left: 0px;
   
}

.selected {
    background-color: #d8d8d848;
    /* border-top-left-radius: 5px;
    border-bottom-left-radius: 5px; */
    border-right: 5px solid var(--teritaryColor);
}

.selected>li>p {
    font-weight: bold !important;
}
.footer {
    position: sticky;
    bottom: 0;
}
.parentList {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    margin-bottom: 10px;
}

p {
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-size: smaller;
    align-items: center;
    align-content: center;
    vertical-align: middle;

}

.titleHolder {
    display: row;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}

.expand-icon {
    justify-self: flex-end;
}

li>ul {
    list-style: none;

    margin: 0;
    margin-bottom: 10px !important;
    /* background-color: #d8d8d82f; */

}

li>ul>li {
    background-color: transparent;
    padding-bottom: 5px !important;
    padding-left: 40px;
  
}

li>ul>li>p {}

.footer {
    background-color: var(--primaryColor);
}
.footerText {
    font-size: 12px;
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    /* Styles for tablet devices */
    .navigation {
        height: 100%;
        width: 50%;
        overflow-y: scroll;
        display: flex;
        position: fixed;
        flex-direction: column;
        justify-content: space-between;
        z-index: 2000;
        background-color: var(--primaryColor);
    } 
}
@media only screen and (max-width: 767px) {
    /* Styles for mobile devices */
    .navigation {
        height: 100%;
        width: 50%;
        overflow-y: scroll;
        display: flex;
        position: fixed;
        flex-direction: column;
        justify-content: space-between;
        background-color: var(--primaryColor);
        z-index: 2000;
    }
        
}
