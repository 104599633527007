.main {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  /* margin-top: 15%; */
 
  /* margin: -30px; */
  /* height: vh; */
  height: 105%;
 
  margin-left: -30px;
  margin-right: -30px;
  /* overflow: hidden !important; */
 
  
  background:-webkit-linear-gradient(270deg,rgba(255, 255, 255, 0) 50%,#0288d1 50%);
  
}

.inputWrapper div:nth-child(1) {
  color: red;
}

.footerWrapper {
    margin-top: -10px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 5px;
}
.footerWrapper > p{
    color: gray;
    font-size: 14px;
    vertical-align: center;
}

.footerWrapper > a{

    color: #0288d1;
    text-decoration: none;
    vertical-align: center;
    margin-bottom: 3px;
    font-size: 14px;
    font-weight: bold;
  
}
.formWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 55px;
  /* margin: 30%; */
  background-color: white;
  border-radius: 30px 10px 30px 10px;
  -moz-border-radius: 30px 10px 30px 10px;
  -webkit-border-radius: 30px 10px 30px 10px;
  border: 0px solid #000000;
  border: 1px solid #ffa25b;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: flex-start;
  margin-top: 3.5%;
  
}

.inputWrapper {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}
.header {
  display: flex;
  align-items: center;
  align-content: center;
  gap: 10px;
}

.headerText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  gap: 5px;
}

.icon > img {
  width: 40px;
  height: 40px;
}

.headerText p:nth-child(1) {
  margin: 0px;
  color: rgb(53, 52, 52);
  font-size: medium;
  font-weight: 700;
  font-family: sans-serif;
}

.headerText p:nth-child(2) {
  margin: 0px;
  color: rgb(53, 52, 52);
  font-size: smaller;
  font-family: Arial, Helvetica, sans-serif;
}

.loginText {
  font-size: medium;
  font-weight: 400;
  color: black;
  text-align: left !important;
  margin-top: 5px;
}

.textContent {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  align-content: flex-start;
}
