.canvasContainer {
  width: 100%;
  max-width: 100%;
}

.forMobileDevices {
  display: none;
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  /* Styles for tablet devices */
  .canvasContainer {
    display: none;
  }
  .forMobileDevices {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  /* Styles for mobile devices */
  .canvasContainer {
    display: none;
  }
  .forMobileDevices {
    display: block;
  }
}
