.infoContainer{
    
    display: "flex";
flex-direction: column;
   justify-content: center; 
   align-items: center;
   align-content: center;
   border: 1px solid #64C2A6;
   margin-top: 20px;
}

.totalNumber {
    text-align: center;
    margin: 0;
    font-weight: bold;
    color: #637179;
    font-family: Arial, Helvetica, sans-serif;
}