.filterPopup {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    background-color: white;
    border-radius: 4px;
    border: 0.5px solid #e4e2e2;
    align-self: center;
}
.headerContainer{
   
}

.exportButton {

    background-color: transparent;
    padding: 5px;
    color: var(--secondaryColor);
    font-size: smaller;
    vertical-align: middle;
    cursor: pointer;
    font-family: Arial, Helvetica, sans-serif;
    border: 0.5px solid var(--secondaryColor);
    border-radius: 3px;
}
.exportButContainer{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    height: fit-content;
    align-content: center;
    
}
.filterPopup > li {
    padding: 4px;
}
.chartTitle {
   
    color: var(--primaryColor);
    text-align: center;
     margin: 0px;
     margin-top: 10px;
     font-size: smaller;
     font-family: Arial, Helvetica, sans-serif;
}
.active {
    background-color: #25486a9d;
}
.active > .chartTitle {
  color: white !important;
}
.chartFilter{
   color: var(--primaryColor);
}
.active:nth-child(1) {
    color: var(--primaryColor);
}

@media only screen and (max-width: 767px) {
    .chartTitle {
        font-size: x-small !important;
    }
    .exportButton {
        font-size: x-small !important;
    }
  }
  
  /* tablet devices */
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    /* Styles for tablet devices */
    .chartTitle {
        font-size: xx-small !important;
    }

    .exportButton {
        font-size: xx-small !important;
    }
  }