.main_containt {
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.oneCol {
  /* max-height: 40vh; */
  overflow-y: hidden;
}

.exportButContainer > p {
  color: var(--primaryColor);
  font-size: smaller;
}
.exportButContainer > div {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
}
.chartTypeIcon {
  color: var(--secondaryColor);
  padding: 6px;
  font-size: smaller;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid var(--secondaryColor);
  border-color: var(--secondaryColor);
}

.filter .filterMobileForm {
  max-height: "fit-content";
  min-height: "fit-content";
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  overflow-x: scroll;
  z-index: 10;
}
.filter {
  display: block;
}
.filterInputs {
  flex: 0.8;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-bottom: 20px;
  /* border-right: 1px solid var(--backgroundColor); */
}

.label {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 11px;
  padding-bottom: 0;
  margin-bottom: 1;
  color: #637179;
}

.multiselect {
  flex: 0.3;
  margin-right: 20px;
}

.filterButton {
  border: 0px;
  padding: 10px;
  width: 100px;
  margin-left: 20px;
  align-self: flex-end;
  align-self: center;
  justify-self: center;
  border-radius: 5px;
  font-weight: bold;
  background-color: var(--secondaryColor);
  color: white;
  bottom: 0;
  position: relative;
  margin-top: 10px;
}

.chartTitle {
  font-size: small;
  color: var(--primaryColor);
  padding: 10px;
}

.filterMobileForm .filterInputs .filterRaw {
  flex-direction: column !important;
  margin-top: 20px;
}


.filterRaw {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.filterRaw > div {
  flex: 1;
}

.filterheader {
  align-content: center;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: 20px;
  box-sizing: border-box;
  border-bottom: 0.3px solid #f2f2f2;
}

.filterIcon {
  align-content: center;
  justify-content: center;
  align-self: center;
}

.twoCol {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  gap: 20px;
  height: fit-content;
  justify-content: space-between;
}
.twoCol2 {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  gap: 20px;
  height: fit-content;
  justify-content: space-between;
}
.twoCol2:nth-child(2) {
  flex: 1;
  align-content: center;
  justify-content: center;
  min-height: fit-content;
  max-height: fit-content;
  background-color: white;
  border-radius: 5px;
  /* padding: 20px; */
  border: 1px solid #a0a0a077;
}
.twoCol2:nth-child(1) {
  flex: 2;
  align-content: center;
  justify-content: center;
  min-height: fit-content;
  max-height: fit-content;
  background-color: white;
  border-radius: 5px;
  /* padding: 20px; */
  border: 1px solid #a0a0a077;
}
.twoColv2 {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  max-height: 70vh;
  height: fit-content;
  justify-content: space-between;
}

.twoColv2:nth-child(1) {
  flex: 2;
  align-content: center;
  justify-content: center;
  min-height: fit-content;
  max-height: fit-content;
  background-color: white;
  border-radius: 5px;
  /* padding: 20px; */
  border: 1px solid #a0a0a077;
}
.twoColv2:nth-child(2) {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 70vh;
}
.twoColv2:nth-child(2):nth-child(1),
.twoColv2:nth-child(2):nth-child(2) {
  flex: 0.5;
  align-content: center;
  justify-content: center;
  min-height: 35vh;

  /* max-height: 35vh; */
  background-color: white;
  border-radius: 5px;
  /* padding: 20px; */
  border: 1px solid #a0a0a077;
}

.twoCol > div {
  flex: 1;
  align-content: center;
  justify-content: center;
  min-height: fit-content;
  max-height: fit-content;
  background-color: white;
  border-radius: 5px;
  /* padding: 20px; */
  border: 1px solid #a0a0a077;
}

.childColumn {
  background-color: white;
  border-radius: 5px;
  /* padding: 20px; */
  border: 1px solid #a0a0a077;
  margin-bottom: 15px;
}

.oneCol {
  height: fit-content;
}
.oneCol > div {
  flex: 1;
  align-content: center;
  justify-content: center;
  min-height: 60vh;
  padding: 20px;
  /* max-height: 60vh; */
  background-color: white;
  border-radius: 5px;
  margin-top: 20px;
  /* padding: 20px; */
  border: 1px solid #a0a0a077;
}

.threeCol {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  gap: 20px;
  justify-content: center;
}

.fourCol {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  gap: 20px;
  justify-content: center;
}
.fourCol > div {
  flex: 1;
  padding: 10px;
  padding-top: 0px;
  border: 0.5px solid #a0a0a077;
  background-color: white;
}

.threeCol2 {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  gap: 20px;
  justify-content: center;
}

.sectionHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 20px;
  padding-left: 10px;
}

.exportButContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 5px;
  border-bottom: 1px solid #f1f1f1;
}

.sectionHeaderTitle {
  font-size: small;
  color: var(--textColor);
  font-family: Arial, Helvetica, sans-serif;
  padding-left: 10px;
}

.threeCol > div {
  padding: 10px;
  padding-top: 0px;
  border: 0.5px solid #a0a0a077;
}
.threeCol2 > div {
  padding: 10px;
  padding-top: 0px;
  border: 0.5px solid #a0a0a077;
}
.threeCol div:nth-child(1) {
  flex: 1;
  align-content: center;
  justify-content: center;
  background-color: white;
  border-radius: 5px;
  min-height: fit-content;
  max-height: fit-content;
}
.threeCol div:nth-child(2) {
  flex: 1;
  min-height: fit-content;
  max-height: fit-content;
  background-color: white;
  border-radius: 5px;
}

.threeCol div:nth-child(3) {
  flex: 1;
  max-height: 50vh;
  max-width: 30vw;
  background-color: white;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  padding: 10;
}

.patientsLinkedContainer {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  gap: 20px;
  height: fit-content;
  justify-content: space-between;
}
.patientsLinkedContainer div:nth-child(1) {
  flex: 2;
}
.patientsLinkedContainer div:nth-child(2) {
  flex: 1;
  max-height: 50vh;
  max-width: 30vw;
  background-color: white;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  padding: 10;
}

.threeCol2 div:nth-child(1) {
  flex: 1;
  align-content: center;
  justify-content: center;
  background-color: white;
  border-radius: 5px;
  min-height: fit-content;
  max-height: fit-content;
}

.threeCol2 div:nth-child(2) {
  flex: 1;
  min-height: fit-content;
  max-height: fit-content;
  background-color: white;
  border-radius: 5px;
}
.
.threeCol2 div:nth-child(3) {
  flex: 1;
  min-height: fit-content;
  max-height: fit-content;
  background-color: white;
  border-radius: 5px;
}

.threeCol2 div:nth-child(1) {
  flex: 1;
  align-content: center;
  justify-content: center;
  background-color: white;
  border-radius: 5px;
  min-height: fit-content;
  max-height: fit-content;
}

.threeCol2 div:nth-child(2) {
  flex: 0.7;
  min-height: fit-content;
  max-height: fit-content;
  background-color: white;
  border-radius: 5px;
}

.threeCol2 div:nth-child(3) {
  flex: 0.7;
  min-height: fit-content;
  max-height: fit-content;
  background-color: white;
  border-radius: 5px;
}
.filterMobile {
  display: none;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  /* CSS rules for medium devices, like tablets */
  .twoCol {
    margin-top: 20px;
    justify-content: space-between;
  }
  .threeCol2 {
    flex-direction: column !important;
  }
  .filter {
    display: none;
  }
  .filterMobile {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  /* Styles for mobile devices */
  .threeCol {
    flex-direction: column !important;
  }
  .threeCol2 {
    flex-direction: column !important;
  }
  .filter {
    display: none;
  }
  .filterMobile {
    display: block;
  }

  .twoCol {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 20px;
    height: fit-content;
    justify-content: space-between;
  }
  .twoCol2 {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 20px;
    height: fit-content;
    justify-content: space-between;
  }
}
