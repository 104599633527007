.loadingContainer{
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: auto;
    /* background-color: aqua; */
}

.errorMessage{
    color: var(--textColor);
    font-size: medium;
}
.tryAgain{
   color: var(--primaryColor);
   font-weight: bold;
   cursor: pointer;
}