.headerContainer {
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  border-bottom: 0.5px solid var(--darkGray);
  width: 100% !important;
}
.bars {
  display: none;
}
/* mobile devices */
@media only screen and (max-width: 767px) {
  /* Styles for mobile devices */
  .bars {
    display: block;
  }
  .icons{
    display: none !important;
  }
  .title {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-size: small;
    flex: 0.9;
  }
  .headerContainer {
    width: 100vw !important;
  }
}

/* tablet devices */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  /* Styles for tablet devices */
  .bars {
    display: block;
  }
  .icons{
    display: none !important;
  }
  .headerContainer {
    width: 100vw !important;
  }
}

.title {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: small;
  flex: 0.9;
}

.icons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-content: center;
  flex: 0.1;
}

