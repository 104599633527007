.parent {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.secondRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 30vh;
  /* background-color: #f2f2f2; */
  gap: 30px;
}

.secondRow > div {
  flex: 0.3;
  border: 0.5px solid #aca7a775;
  border-radius: 5px;
  padding: 10px;
}
.secondRow > div > div {
  flex: 0.3;
  border: 60px solid #aca7a775;
  border-radius: 100%;
  height: 15vh;
  margin: auto;
  width: 15vh;
  align-self: center;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.charttitle {
  color: var(--primaryColor);
  text-align: center;
  margin: 0px;
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: smaller;
  font-family: Arial, Helvetica, sans-serif;
}
.number {
  text-align: center;
  margin: 0;
  font-weight: bold;
  color: #637179;
  font-size: xx-large;
  font-family: Arial, Helvetica, sans-serif;
}
.firstRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  gap: 15px;
}
.firstRow .firstCol {
  flex: 0.7;
  /* border: 0.5px solid #aca7a775; */
  border-radius: 5px;
  padding: 5px;
}
.firstRow .secondCol {
  flex: 0.3;
  max-height: 50vh;
  max-width: 30vw;
  background-color: white;
  border-radius: 5px;
  overflow: hidden;
  position: absolute;
  padding: 10;
}
