.infoContainer{
    
    display: "flex";
flex-direction: column;
   justify-content: center; 
   align-items: center;
   align-content: center;
   border: 1px solid #64C2A6;
   margin-top: 20px;
}

.totalNumber {
    text-align: center;
    margin: 0;
    font-weight: bold;
    background: linear-gradient(to right,  #2D87BB,#64C2A6);
    -webkit-background-clip: text; /* For Safari */
    background-clip: text;
    color: transparent;
   
    font-family: Arial, Helvetica, sans-serif;
}