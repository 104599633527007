.dashboardWrapper {
  background-color: var(--backgroundColor);
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 100%;
  position: absolute;
}

.mainWrapper {
  background-color: var(--backgroundColor);
  display: flex;
  flex-direction: column;
  flex: 0.85;
}

.visualsContainer {
  height: 100%;
  /* Take up the remaining height */
  overflow: hidden;
  /* Enable scrolling if content overflows */
  padding: 10px;
  padding-left: 20px;
  /* Add some padding if needed */
}

.navigationContainer {
  flex: 0.15;
  height: 100vh !important;
  background-color: var(--primaryColor);
  position: sticky;
  z-index: 1;
  top: 0;
  opacity: 1;
  transition: opacity 1s ease 0s;
}

.header {
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 1000;
  display: block;
}
.mobileHeader {
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 1000;
  display: none;
}
.navigationContainer {
  display: block;
}

.mobileNavigationContainer {
  display: none;
}
@media screen and (max-width: 767px) {
  /* CSS rules for small devices, like mobile phones */
  .navigationContainer {
    display: none;
  }
  .mobileNavigationContainer {
    display: block;
  }
  .header {
    display: none !important;
  }
  .mobileHeader {
    display: block;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  /* CSS rules for medium devices, like tablets */
  .dashboardWrapper {
    flex-direction: column;
  }
  .navigationContainer {
    display: none;
  }
  .mobileNavigationContainer {
    display: block;
  }
  .header {
    display: none !important;
  }
  .mobileHeader {
    display: block;
  }
}
